<template>
<b-row>
  <b-col cols="12" xl="6" class="nopadding">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item">
        <a class="nav-link" id="order-tab" data-toggle="tab" v-on:click="activetab=2" v-bind:class="[ activetab === 2 ? 'show active' : '' ]"
        role="tab" aria-controls="order" aria-selected="false"><i class="fa fa-list" aria-hidden="true"></i> {{$t('common.order_list')}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="object-tab" data-toggle="tab" v-on:click="activetab=1" v-bind:class="[ activetab === 1 ? 'show active' : '' ]"
        role="tab" aria-controls="object" aria-selected="true"><i class="fa fa-file-text-o" aria-hidden="true"></i> {{$t('common.room_list')}}</a>
      </li>
    </ul>
    <div class="tab-content" :id="'tab_booking_room'">
      <div class="tab-pane fade" v-bind:class="[ activetab === 1 ? 'show active' : '' ]" id="tab_pane_booking_room" role="tabpanel" aria-labelledby="booking-tab">
        <b-form-group label-for="table-style-variant" label-cols-lg="2">
          <div class="input-group">
            <input type="text" class="form-control" :placeholder="$t('common.search_text')"
            v-model="filter_object" maxlenght="50" @keyup.enter="apply_filter_object">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" @click="apply_filter_object"
                type="button"><i class="fa fa-search" aria-hidden="true"></i> {{$t('common.search')}}</button>
            </div>
          </div>
        </b-form-group>
        <div class="row div-card-scroll">
          <div class="col">
            <div class="row" v-if="filteredAndSortedData_object.length>0">
              <div class="col-md-4 col-xs-3 form-fixer" v-for="itm in filteredAndSortedData_object" :key="itm.id">
                <b-card class="booking-detail"
                  @click="book_new_object(itm)"
                  :border-variant="itm.status>0?'primary':''">
                  <div class="d-flex justify-content-between align-items-center" slot="header">
                    <div :class="itm.status>0?'bg-primary':''">
                      <i v-if="itm.number">{{itm.name}}-{{itm.number}}</i>
                      <i v-else>{{itm.name}}</i>
                    </div>
                    <div class="bg-primary" v-if="itm.status>0">
                      <i>{{get_intime(itm)}}</i>
                    </div>
                  </div>
                  <div v-if="itm.status>0">
                    <label class="row">{{$t('common.total')}}: {{formatPrice(get_total(itm))}}</label>
                    <label class="row pull-right"><small class="text-muted">{{$t('common.updated_time',{hour:day_diff(get_update_time(itm))})}}</small></label>
                  </div>
                  <div v-else>
                    <label>{{$t('common.click_to_order')}}</label>
                  </div>

                  <div v-if="itm.status>0" class="d-flex justify-content-between align-items-center" slot="footer">
                    <div class="pull-left">
                      <group-button v-model='bill_id' :items='ppl' @selected='client_bill_pdf(itm)'></group-button>
                    </div>
                    <div class="btn-group pull-right">
                      <button type="button" class="btn btn-success btn-sm"
                      @click="confirmReleaseObject(itm)">{{$t('common.release',{obj:$t('common.room')})}}</button>
                    </div>
                  </div>
                  <div class="row" slot="footer" v-else>
                    <div class="col h6 text-center">
                      {{$t("common.no_order")}}
                    </div>
                  </div>
                </b-card>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-show="false">
          <div class="col">
            <pager class="pull-right" :frame="8"
                  :pageCount="page_count_object"
                  :page="page_object"
                  @change="change_page_object">
            </pager>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" v-bind:class="[ activetab === 2 ? 'show active' : '' ]" id="tab_pane_order_table" role="tabpanel" aria-labelledby="order-tab">
        <b-form-group label-for="table-style-variant" label-cols-lg="2">
          <div class="input-group">
            <input type="text" class="form-control" :placeholder="$t('common.search_text')"
            v-model="filter_order" maxlenght="50" @keyup.enter="apply_filter_order">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" @click="apply_filter_order"
                type="button"><i class="fa fa-search" aria-hidden="true"></i> {{$t('common.search')}}</button>
            </div>
          </div>
        </b-form-group>
        <b-table striped hover responsive :tbody-tr-class="rowDeleted"
        :items="filteredAndSortedData_order" :fields="fields" :current-page="page_order"
        :per-page="0" @row-clicked="rowClicked">
          <template slot="top-row" slot-scope="{ fields }">
            <td v-for="field in fields" :key="field.key">
              <input v-model="filteredAndSortedData[field.key]" 
              v-if="field.key && field.label" :placeholder="field.label">
            </td>
          </template>
          <template slot="branch" slot-scope="data">
            <label v-if="data.item.branch">{{data.item.branch.name}}</label>
            <label v-else>{{data.item.company.name}}</label>
          </template>
          <template slot="company" slot-scope="data">
            <label v-if="data.item.company">{{data.item.company.name}}</label>
            <label v-else></label>
          </template>
          <template slot="client" slot-scope="data">
            <label v-if="data.item.client">{{data.item.client.name}}</label>
            <label v-else></label>
          </template>
          <template slot="object" slot-scope="data">
            <label v-if="data.item.object">{{data.item.object.name}} {{data.item.object.number}}</label>
            <label v-else></label>
          </template>
          <template slot="total" slot-scope="data">
            <label v-if="data.item.total>0">{{formatPrice(data.item.total)}}</label>
            <label v-else>0</label>
          </template>
          <template slot="status" slot-scope="data">
            <b-badge :variant="getBadge(data.item.status)">{{getOrderStatus(data.item.status)}}</b-badge>
          </template>

          <template slot="created_at" slot-scope="data">
            <label>{{VnDateTimeFormat(data.item.created_at)}}</label>
          </template>
          <template slot="bill" slot-scope="data">
            <b-button-group size="sm">
              <b-button variant="primary" @click="order_bill_pdf(data.item.id,80)" v-b-modal.bill_viewModal><i
                  class="fa fa-file-pdf-o"></i> bill 80</b-button>
              <b-button variant="primary" @click="order_bill_pdf(data.item.id,57)" v-b-modal.bill_viewModal><i
                  class="fa fa-file-pdf-o"></i> bill 57</b-button>
              <b-button v-if="false" variant="primary" @click="order_bill_pdf(data.item.id,5)" v-b-modal.bill_viewModal><i
                  class="fa fa-file-pdf-o"></i> bill A5</b-button>
            </b-button-group>
          </template>
          <template slot="action" slot-scope="data">
            <b-button-group size="sm">
              <b-button variant="outline-danger"
              v-if="data.item.status>-1"
                @click="confirmDelete(data.item)">
                <i class="fa fa-trash"></i> {{$t('common.button.delete')}}
              </b-button>
              <b-button variant="outline-success" v-else
                @click="recoverObj(data.item)">
                <i class="fa fa-recycle"></i> {{$t('common.button.recover')}}
              </b-button>
            </b-button-group>
          </template>
        </b-table>
        <div class="row">
          <b-col>
            <pager class="pull-right" :frame="8"
                  :pageCount="page_count_order"
                  :page="page_order"
                  @change="change_page_order">
            </pager>
          </b-col>
        </div>
      </div>
    </div>
  </b-col>

  <b-col cols="12" xl="6" class="nopadding">
    <b-card>
      <div class="row">
        <div class="col-4 form-fixer">
          <div class="form-group">
            <label for="ddl_list_object_ddl" class="control-label mr-1">{{$t('common.room')}}</label>
            <div v-if="$v.order.object_id.$error" class="pull-right">
              <div class="text-danger" v-if="!$v.order.object_id.required">{{$t('common.room_required')}}</div>
            </div>
            <b-form-select id="ddl_list_object_ddl"
              @change="onRoomChange(order.object_id)"
              v-model.trim="order.object_id" size="sm"
              :placeholder="$t('common.select_room')">
              <option value="null" disabled>{{$t('common.select_room')}}</option>
              <option v-for="c in ls_object_ddl" :value="c.id"
              v-bind:key="c.id">{{c.name}} - [{{c.price}}]</option>
            </b-form-select>
          </div>
        </div>
        <div class="col-3 float-sm-right form-fixer">
          <div class="form-group">
            <label for="txt_discount" class="control-label mr-1">{{$t('common.discount')}}</label>
            <money v-model.trim="order.discount" v-bind="money_discount" class="form-control text-success"
            id="txt_discount" maxLength="4"></money>
          </div>
        </div>
        <div class="col-5">
          <strong class="pull-right text-danger h4"><i class="fa fa-money 2x"
          aria-hidden="true"></i> {{formatPrice(total_recal)}} {{$t('common.money_icon')}}</strong>
        </div>
      </div>
      <div class="row">
        <div class="col form-fixer">
          <div class="form-group">
            <label for="ddl_list_client_ddl" class="control-label mr-1">{{$t('common.client')}}</label>
            <div v-if="$v.order.client_list.$error" class="pull-right">
              <div class="text-danger" v-if="!$v.order.client_list.required">{{$t('common.client_required')}}</div>
            </div>
            <multiselect v-model="order.client_list" :tag-placeholder="$t('common.select_client')"
            :placeholder="$t('common.placeholder_client')" label="name" :select-label="$t('common.enter_to_select')"
            track-by="id" :options="ls_client_ddl" :multiple="true"
            :taggable="true" :deselect-label="$t('common.enter_to_remove')"></multiselect>
          </div>
        </div>
      </div>
      <div class="row">
        <fieldset class="scheduler-border col">
          <legend class="scheduler-border h4">{{$t('common.electric_number')}}</legend>
          
          <div class="row form-fixer">
            <div class="col form-group form-fixer">
              <label for="txt_new_number_electric" class="control-label">{{$t('common.old_number')}}</label>
              <div v-if="$v.order.old_electric.$error" class="pull-right">
                <div class="text-danger" v-if="!$v.order.old_electric.required">{{$t('common.old_number_required')}}</div>
                <div class="text-danger" v-else-if="!$v.order.old_electric.minValue">{{$t('common.greater_than_zero')}}</div>
              </div>
              <b-form-input type="number" id="txt_old_electric" size="sm"
              v-model="order.old_electric" maxlength="10"></b-form-input>
            </div>
            <div class="col form-group form-fixer">
              <label for="txt_discount" class="control-label mr-1">{{$t('common.new_number')}}</label>
              <div v-if="$v.order.new_electric.$error" class="pull-right">
                <div class="text-danger" v-if="!$v.order.new_electric.required">{{$t('common.new_number_required')}}</div>
                <div class="text-danger" v-else-if="!$v.order.new_electric.minValue">{{$t('common.greater_than_zero')}}</div>
              </div>
              <b-form-input type="number" id="txt_new_number_electric" size="sm"
              v-model="order.new_electric" maxlength="10"></b-form-input>
            </div>
            <div class="col form-group form-fixer">
              <label for="txt_price_electric" class="control-label mr-1">{{$t('common.price')}}</label>
              <div v-if="$v.order.price_electric.$error" class="pull-right">
                <div class="text-danger" v-if="!$v.order.price_electric.required">{{$t('common.price_required')}}</div>
              </div>
              <money v-model.trim="order.price_electric" v-bind="money" class="form-control text-success"
              id="txt_price_electric" maxLength="18"></money>
            </div>
            <div class="col form-group form-fixer">
              <label for="txt_total_electric" class="control-label mr-1">{{$t('common.total')}}</label>
              <money v-model.trim="order.total_electric" v-bind="money" class="form-control text-success"
              id="txt_total_electric" maxLength="18" :disabled="true"></money>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="row">
        <fieldset class="scheduler-border col">
          <legend class="scheduler-border h4">{{$t('common.water_number')}}</legend>
            <div class="row">
              <div class="col form-group form-fixer">
                <label for="txt_old_number_water" class="control-label">{{$t('common.old_number')}}</label>
                <div v-if="$v.order.old_water.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.order.old_water.required">{{$t('common.old_number_required')}}</div>
                <div class="text-danger" v-else-if="!$v.order.old_water.minValue">{{$t('common.greater_than_zero')}}</div>
                </div>
                <b-form-input type="number" id="txt_old_water" size="sm"
                v-model="order.old_water" maxlength="10"></b-form-input>
              </div>
              <div class="col form-group form-fixer">
                <label for="txt_discount" class="control-label mr-1">{{$t('common.new_number')}}</label>
                <div v-if="$v.order.new_water.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.order.new_water.required">{{$t('common.new_number_required')}}</div>
                <div class="text-danger" v-else-if="!$v.order.new_water.minValue">{{$t('common.greater_than_zero')}}</div>
                </div>
                <b-form-input type="number" id="txt_old_number_water" size="sm"
                v-model="order.new_water" maxlength="10"></b-form-input>
              </div>
              <div class="col form-group form-fixer">
                <label for="txt_price_water" class="control-label mr-1">{{$t('common.price')}}</label>
                <div v-if="$v.order.price_water.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.order.price_water.required">{{$t('common.price_required')}}</div>
                </div>
                <money v-model.trim="order.price_water" v-bind="money" class="form-control text-success"
                id="txt_price_water" maxLength="18"></money>
              </div>
              <div class="col form-group form-fixer">
                <label for="txt_total_water" class="control-label mr-1">{{$t('common.total')}}</label>
                <money v-model.trim="order.total_water" v-bind="money" class="form-control text-success"
                id="txt_total_water" maxLength="18" :disabled="true"></money>
              </div>
            </div>
        </fieldset>
      </div>
      <div class="row">
        <label for="'txt_des">{{$t('common.description')}}</label>
        <textarea class="form-control" maxlength="300" id="txt_des" size="sm"
        v-model.trim="order.des" :placeholder="$t('common.placeholder_description')"></textarea>
      </div>
      <div class="row">
        <fieldset class="scheduler-border col">
          <legend class="scheduler-border h4">{{$t('common.product_list')}}</legend>
          <div class="div-scroll row">
            <div class="col">
              <div class="row" v-for="(itm, k) in $v.order.order_detail.$each.$iter"
                :key="k" :class="['', (itm.status.$model>-1?(k%2==0?'bg-light':''):'bg-secondary')]">
                <div class="form-group col-md-4 form-fixer-list">
                  <label :for="'txt_product_'+k">{{$t('common.product')}}</label>
                  <div v-if="order.object_id&&$v.order.order_detail.$error" class="pull-right">
                    <div class="text-danger" v-if="!itm.product_id.required">{{$t('common.product_required')}}</div>
                    <div class="text-danger" v-else-if="!itm.product_id.isUnique">{{$t('common.product_existing')}}</div>
                  </div>
                  <b-form-select v-model.trim="itm.product_id.$model"
                    :id="'ddl_product_'+k" v-on:input="itm.product_id.$touch"
                    :disabled="filteredAndSortedData_object.length<=0 || $v.order.object_id.$model==null"
                    @change="onProductChange(k,itm.product_id.$model)" size="sm">
                    <option value="null" disabled>{{$t('common.product')}}</option>
                    <option v-for="c in ls_product_ddl" :value="c.id"
                    v-bind:key="c.id">{{c.name}} {{c.supply?" - ["+c.supply.name+"]":""}}</option>
                  </b-form-select>
                </div>
                <div class="form-group col-md-3 form-fixer-list">
                  <label :for="'ddl_unit_'+k">{{$t('common.unit')}}</label>
                  <div v-if="order.object_id&&$v.order.order_detail.$error" class="pull-right">
                    <div class="text-danger" v-if="!itm.unit_id.required">{{$t('common.unit_required')}}</div>
                  </div>
                  <b-form-select :id="'ddl_unit_'+k" class="form-control" :disabled="itm.product_id.$model==null"
                  v-model="itm.unit_id.$model" @change="onUnitChange(k,itm.unit_id.$model)" size="sm">
                      <option value="null" disabled>{{$t('common.select_unit')}}</option>
                      <option v-for="d in order.order_detail[k].ls_unit_ddl" :value="d.id"
                          v-bind:key="d.id">{{JSON.parse(d.name)[$i18n.locale]}}</option>
                  </b-form-select>
                </div>
                <div class="form-group col-md-2 form-fixer-list">
                  <label :for="'txt_price_'+k">{{$t('common.price')}}</label>
                  <div v-if="order.object_id&&$v.order.order_detail.$error" class="pull-right">
                    <div class="text-danger" v-if="!itm.price.required">{{$t('common.price_required')}}</div>
                  </div>
                  <money v-model.trim="itm.price.$model" v-bind="money" class="form-control"
                  :name="'txt_price_'+k" :id="'txt_price_'+k" :disabled="itm.product_id.$model==null ||itm.unit_id.$model==null"
                  @change.native="onPriceChange(k,itm.price.$model)"
                  maxLength="15"></money>
                </div>
                <div class="form-group col-md-2 form-fixer-list">
                  <label :for="'txt_quantity_'+k">{{$t('common.quantity')}}</label>
                  <div v-if="order.object_id&&itm.product_id.$model&&itm.unit_id.$model&&$v.order.order_detail.$error" class="pull-right">
                    <div class="text-danger" v-if="!itm.quantity.required">{{$t('common.quantity_required')}}</div>
                    <div class="text-danger" v-else-if="!itm.quantity.numeric">{{$t('common.quantity_number_only')}}</div>
                    <div class="text-danger" v-else-if="!itm.quantity.maxValue">{{$t('common.quantity_max')}}</div>
                  </div>
                  <b-form-input type="number" @change="onQualityChange(k,itm.quantity.$model)"
                    v-model.trim="itm.quantity.$model" class="form-control" min="1" max="1000" size="sm"
                    :name="'txt_quantity_'+k" :id="'txt_quantity_'+k" :disabled="itm.product_id.$model==null ||itm.unit_id.$model==null"
                    maxLength="15"></b-form-input>
                </div>
                <div class="col-md-1 pull-right text-white form-inline" v-if="itm.status.$model>-1">
                  <div v-if="order.id==null">
                    <a class="btn btn-danger mr-1 btn-sm" v-if="order.order_detail.length>0"
                      @click="removeRow(k)"><i class="fa fa-minus" aria-hidden="true"></i></a>
                    <a class="btn btn-success btn-sm" @click="addNewRow"><i class="fa fa-plus" aria-hidden="true"></i></a>
                  </div>
                  <div v-else>
                    <a class="btn btn-danger mr-1 btn-sm"
                    @click="removeRow(k)"><i class="fa fa-minus" aria-hidden="true"></i></a>
                    <a class="btn btn-success btn-sm" @click="addNewRow"><i class="fa fa-plus" aria-hidden="true"></i></a>
                  </div>
                </div>
                <div class="col-md-1 pull-right text-white form-inline" v-else>
                  <a class="btn btn-success mr-1 btn-sm" v-if="order.order_detail.length>0"
                    @click="itm.status.$model=0"><i class="fa fa-refresh" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="row">
        <b-button variant="outline-primary mr-1" size="sm" @click.prevent="save_order()"><span class="icon is-small">
          <i class="fa fa-check"></i> {{$t('common.button.save')}} </span></b-button>
        <b-button variant="outline-success" size="sm" @click.prevent="clear()"><span class="icon is-small">
          <i class="fa fa-refresh"></i> {{$t('common.button.refresh')}}</span></b-button>
      </div>
    </b-card>
  </b-col>
  <b-modal ref="bill_viewModal" id="bill_viewModal" :title="$t('common.bill_info')" size="lg" header-bg-variant="info" no-body ok-only>
    <div id="cafe_order_bill_pdf" style="height:450px;"></div>
  </b-modal>
</b-row>
</template>
<style scoped>
  label{
    margin-bottom: 0rem;
  }
  fieldset.scheduler-border {
    border: 1px groove #e4e7ea !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow:  0px 0px 0px 0px #000;
            box-shadow:  0px 0px 0px 0px #000;
  }
  .div-scroll {
    max-height:200px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  legend.scheduler-border {
    font-size: 1.2em !important;
    font-weight: bold !important;
    text-align: left !important;
    width:auto;
    padding:0 10px;
    border-bottom:none;
  }
  div.card.booking-detail > div.card-body{
    padding-left: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  div.card.booking-detail > div.card-header{
    padding: 0px !important;
    border-bottom:0px !important;
  }
  div.card.booking-detail > div.card-footer{
    padding: 0px !important;
    border-top:0px !important;
  }
</style>
<script>
  import pdfobj from 'pdfobject'
  import { validationMixin } from 'vuelidate'
  import { required,requiredIf, numeric } from 'vuelidate/lib/validators'
  import { mapGetters, mapActions } from 'vuex'
  import datePicker from 'vue-bootstrap-datetimepicker'
  import moment from 'moment'
  import Pager from '../Pager'
  import { ModelSelect } from 'vue-search-select'
  import '../../../node_modules/vue-toastr-2/dist/vue-toastr-2.min.css'
  import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
  import { Role } from '@/helpers/role.js'
  import Multiselect from 'vue-multiselect'
  import GroupButton from '../custom/GroupButton'

  const greaterThanZero = (value) => value > 0
  export default {
    components: { datePicker,ModelSelect,Pager,VueBootstrapTypeahead,Multiselect,GroupButton},
    mixins: [validationMixin],
    props: {
      hover: {
        type: Boolean,
        default: true
      }
    },
    data: function () {
      return {
        bill_id: 80,
        activetab: 1,
        processing:false,
        ls_gender: [],
        money_discount: {
          decimal: ',',
          thousands: '.',
          prefix: '% ',
          precision: 0,
          masked: false
        },
        money: {
          decimal: ',',
          thousands: '.',
          prefix: '$ ',
          precision: 0,
          masked: false
        },
        order: {
          id:null,
          object_id:null,
          order_detail:[],
          user_create_id:null,
          user_update_id:null,
          total:0,
          old_water:0,
          new_water:0,
          old_electric:0,
          new_electric:0,
          company_id:null,
          business:null,
          branch_id:null,
          client_list:[],
          des:null,
          discount:0,
          discount_reason:null,
          code:moment().valueOf().toString(),
          total_electric:0,
          total_water:0,
          price_electric:0,
          price_water:0,
        },
        options: {
          format: 'DD-MM-YYYY',
          useCurrent: true,
          showClear: true,
          showClose: true
        },
        options1: {
          format: 'DD-MM-YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true
			  }
      }
    },
    validations: {
      order: {
        client_list:{
          required
        },
        price_electric:{
          required
        },
        price_water:{
          required
        },
        old_water:{
          required,
          numeric,
          minVal:greaterThanZero
        },
        new_water:{
          required,
          numeric,
          minVal:greaterThanZero
        },
        old_electric:{
          required,
          numeric,
          minVal:greaterThanZero
        },
        new_electric:{
          required,
          numeric,
          minVal:greaterThanZero
        },
        object_id:{
          required
        },
        order_detail: {
          $each: {
            product_id:{
              required: requiredIf(function () {
                return this.order.order_detail.length>0 &&this.order.object_id
              }),
              async isUnique(value,itm) {
                debugger
                if(this.order.order_detail.length<=0) return Boolean(true)
                let arr=this.order.order_detail.filter(x => (x.status>-1&&x.unit_id == itm.unit_id && x.product_id==itm.product_id))
                return Boolean(arr.length<=1)
              }
            },
            unit_id:{
              required: requiredIf(function (itm) {
                debugger
                return this.order.order_detail.length>0 && itm.product_id
              })
            },
            quantity:{
              required: requiredIf(function (itm) {
                return this.order.order_detail.length>0 && itm.product_id
              }),
              maxValue: greaterThanZero,
              numeric
            },
            total:{
              required: requiredIf(function (itm) {
                return this.order.order_detail.length>0 && itm.product_id
              }),
              numeric
            },
            price:{
              required: requiredIf(function (itm) {
                return this.order.order_detail.length>0 && itm.product_id
              }),
              numeric
            },
            status:{},
            ls_unit_ddl:[]
          }
        }
      }
    },
    computed: {
      ...mapGetters('st_order', ['ls_order','per_page_order','filter_order','page_count_order','page_order']),
      ...mapGetters('st_client', ['ls_object_ddl']),
      ...mapGetters('st_object', ['ls_object','ls_object_ddl','per_page_object','filter_object',
      'page_count_object','page_object']),
      fields(){
        let fields=[]

        let user=this.$store.state.st_authentication.user
        if(user.role==Role.Super_Admin){
          fields.push({key: 'company',label:this.$t('common.company'),sortable: true, variant:'info'})
        }else if(user.role==Role.Admin){
          fields.push({key: 'branch',label:this.$t('common.company_branch'),sortable: true, variant:'info'})
        }

        fields.push({key: 'object',label:this.$t('common.table'),sortable: true})
        fields.push({key: 'discount',label:this.$t('common.discount'),sortable: true})
        fields.push({key: 'total',label:this.$t('common.total'),sortable: true})
        fields.push({key: 'status',label:this.$t('common.status'),sortable: true})
        fields.push({key: 'bill',label:this.$t('common.bill'),sortable: false})

        if(user.role==Role.Super_Admin||user.role==Role.Admin||user.role==Role.Branch_Admin){
          fields.push({key: 'action',label:'',sortable: false})
        }

        return fields
      },
      total_recal(){
        let temp=_.sumBy(this.order.order_detail.filter(x=>x.status>=0),"total")
        return parseInt(temp-((temp)*(this.order.discount/100)))
      },
      ls_product_ddl(){
        let ls= this.$store.state.st_product.ls_product_ddl
        return ls
      },
      is_super () {
        let user= this.$store.state.st_authentication.user
        return user.role==Role.Super_Admin
      },
      business () {
        let user= this.$store.state.st_authentication.user
        return user.company.business
      },
      user_login () {
        return this.$store.state.st_authentication.user
      },
      filter_order:{
        get: function(){
          return this.$store.state.st_order.filter_order
        },
        set: function(text){
          this.$store.commit('st_order/set_filter',text)
        }
      },
      per_page_object:{
        get: function(){
          return this.$store.state.st_object.per_page_object
        },
        set: function(page){
          this.$store.commit('st_object/set_per_page',page)
        }
      },
      filter_object:{
        get: function(){
          return this.$store.state.st_object.filter_object
        },
        set: function(text){
          this.$store.commit('st_object/set_filter',text)
        }
      },
      ls_unit_ddl(){
        return this.$store.state.st_unit.ls_unit_ddl
      },

      ls_object_ddl(){
        return this.$store.state.st_object.ls_object_ddl
      },

      ls_client_ddl(){
        return this.$store.state.st_client.ls_client_ddl
      },

      filteredAndSortedData_order:{
        get: function(){
          let vm=this
          debugger
          let result = vm.$store.state.st_order.ls_order
          if (vm.filter) {
            result = result.filter(item =>vm.searchLike(item))
          }

          return result
        }
      },
      filteredAndSortedData_object:{
        get: function(){
          let vm=this
          let result = vm.$store.state.st_object.ls_object
          if (vm.filter_object) {
            result = result.filter(item =>vm.searchLike_object(item))
          }

          return result
        }
      }
    },
    methods: {
      ...mapActions('st_order', ['get_ls_order','apply_filter_order','change_page_order']),
      ...mapActions('st_unit', ['get_ls_unit_ddl']),
      ...mapActions('st_client', ['get_ls_client_ddl']),
      ...mapActions('st_product', ['get_ls_product_ddl']),
      ...mapActions('st_object', ['get_ls_object','get_ls_object_ddl','apply_filter_object','change_page_object']),
      day_diff(str_date_time){
        debugger
        let now=moment()
        let another_date=moment(str_date_time, 'YYYY-MM-DD hh:mm:ss')
        let diff=now.diff(another_date, 'minutes')
        if(diff<60){
          return diff + ' ' +this.$t("common.minutes")+ ' '
        }else if(diff<(60*24)){
          return now.diff(another_date, 'hours') + ' ' +this.$t("common.hours")+ ' '
        }else if(diff<(60*24*7)){
          return now.diff(another_date, 'days') + ' '+this.$t("common.days")+ ' '
        }else{
          return now.diff(another_date, 'weeks') + ' '+this.$t("common.weeks")+ ' '
        }
      },
      get_update_time(obj){
        let order= this.filteredAndSortedData_order.find(x=>x.object_id==obj.id&& x.company_id==obj.company_id&&x.branch_id==obj.branch_id&& x.status==1)
        if(order) return order.updated_at
        return null
      },
      get_intime(obj){
        let order= this.filteredAndSortedData_order.find(x=>x.object_id==obj.id&& x.company_id==obj.company_id&&x.branch_id==obj.branch_idbranch_id&& x.status==1)
        if(order)
        {
          let today=moment(order.created_at)
          let diff=moment().diff(today, 'minutes')
          return diff + "' " +this.$t("common.before")
        }

        return null
      },
      get_total(obj){
        let order= this.filteredAndSortedData_order.find(x=>x.object_id==obj.id&& x.company_id==obj.company_id&&x.branch_id==obj.branch_idbranch_id&& x.status==1)
        if(order) return order.total
        return 0
      },
      rowDeleted(item, type){
        if (!item || type !== 'row') return
        if (item.status === -1) return 'table-danger'
      },
      release_object(id){
        let app=this
        app.$store.dispatch('st_order/release_object',{order_id:id,user_update_id:this.user_login.id,business:app.user_login.company.business})
        .then(function (resp) {
          app.get_ls_order()
          app.get_ls_object()
          app.clear()
          app.$toastr.success(app.$t("common.recovered_success"),app.$t("common.message"))
          app.processing = false
        }).catch(function (resp) {
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
          app.processing = false
        })
      },
      confirmReleaseObject(obj) {
        let order=this.filteredAndSortedData_order.find(x=>x.object_id==obj.id&&x.status==1)
        if(!order) return
        this.$bvModal.msgBoxConfirm('Bạn muốn hoàn tất bàn [' +obj.name+'] ?', {
          title: this.$t("common.confirm_delete"),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Ok',
          cancelTitle: 'Không',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: false
        })
        .then(value => {
          if(value!=true) return
          this.release_object(order.id)
        })
        .catch(err => {
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        })
      },
      async book_new_object(obj){
        if(!obj) return
        debugger
        if(obj.status<1){
          this.clear()
          this.order.object_id=obj.id
        }else{
          let temp_order=this.filteredAndSortedData_order.find(x=>x.object_id==obj.id&&x.status==1)
          if(!temp_order) return
          this.order=temp_order
          if(!Array.isArray(this.order.client_list)){
            this.order.client_list=JSON.parse(this.order.client_list.replace(/'/g, '"'))
          }
          for (var i = 0; i < this.order.order_detail.length; i++) {
            let element = this.order.order_detail[i]
            let product=this.ls_product_ddl.find(x=>x.id==element.product_id)
            if(product){
              var filteredArray  = this.ls_unit_ddl.filter(function(array_el){
                return product.product_units.filter(function(anotherOne_el){
                  if(anotherOne_el.unit_id == array_el.id){
                    array_el.buy_price= anotherOne_el.buy_price
                    array_el.sell_price= anotherOne_el.sell_price
                  }
                  return anotherOne_el.unit_id == array_el.id
                }).length > 0
              })

              if(filteredArray.length<=0){
                filteredArray=this.ls_product_ddl
              }

              element.ls_unit_ddl=filteredArray
            }
          }
        }
      },
      client_bill_pdf(obj){
        debugger
        let app=this
        let order=this.filteredAndSortedData_order.find(x=>x.object_id==obj.id&&x.status==1)
        if(!order) return
        app.$refs.bill_viewModal.show()
        app.order_bill_pdf(order.id,app.bill_id)
      },
      order_bill_pdf(id,type){
        debugger
        let app=this
        var loader = app.$loading.show()
        app.$store.dispatch('st_order/bill_pdf',{id,type,business:app.business,lang:app.$i18n.locale}).then((response) => {
          if(response && response.data){
            let blob = new Blob([response.data], { type: 'application/pdf' })
            pdfobj.embed(window.URL.createObjectURL(blob), "#cafe_order_bill_pdf")
          }

          loader.hide()
        })
      },
      onRoomChange(object_id){
        if(!object_id) return
        debugger
        let temp_order=this.filteredAndSortedData_order.find(x=>x.object_id==object_id&&x.status==1)
        if(temp_order){
          this.order=temp_order
          for (var i = 0; i < this.order.order_detail.length; i++) {
            let element = this.order.order_detail[i]
            let product=this.ls_product_ddl.find(x=>x.id==element.product_id)
            if(product){
              var filteredArray  = this.ls_unit_ddl.filter(function(array_el){
                return product.product_units.filter(function(anotherOne_el){
                  if(anotherOne_el.unit_id == array_el.id){
                    array_el.buy_price= anotherOne_el.buy_price
                    array_el.sell_price= anotherOne_el.sell_price
                  }
                  return anotherOne_el.unit_id == array_el.id
                }).length > 0
              })

              if(filteredArray.length<=0){
                filteredArray=this.ls_product_ddl
              }

              element.ls_unit_ddl=filteredArray
            }
          }
        }
      },
      onObjectChange(id){
        debugger
        let obj=this.ls_object_ddl.find(x=>x.id==id)
        if(obj){
          this.order.price=obj.price
        }
      },
      onProductChange(k,id){
        if(id==null) return
        debugger
        let product=this.ls_product_ddl.find(x => x.id === id)
        k=parseInt(k)
        if(product){
          var filteredArray  = this.ls_unit_ddl.filter(function(array_el){
            return product.product_units.filter(function(anotherOne_el){
              if(anotherOne_el.unit_id == array_el.id){
                array_el.buy_price= anotherOne_el.buy_price
                array_el.sell_price= anotherOne_el.sell_price
              }
              return anotherOne_el.unit_id == array_el.id
            }).length > 0
          })

          if(filteredArray.length<=0){
            filteredArray=this.ls_product_ddl
          }

          this.order.order_detail[k].ls_unit_ddl=filteredArray
          this.order.order_detail[k].price=product.sell_price
        }else{
          this.order.order_detail[k].price=0
        }

        this.order.order_detail[k].quantity=0
        this.order.order_detail[k].total=0
        this.order.order_detail[k].unit_id=null
      },
      onUnitChange(k,unit_id){
        if(unit_id==null) return
        debugger
        k=parseInt(k)
        let unit=this.order.order_detail[k].ls_unit_ddl.find(x=>x.id==unit_id)
        this.order.order_detail[k].quantity=1
        if(unit){
          this.order.order_detail[k].total=this.order.order_detail[k].price=unit.sell_price
        }
      },
      onPriceChange(k,event){
        debugger
        if(event==null) return
        debugger
        k=parseInt(k)
        if(this.order&&this.order.order_detail[k].quantity>0){
          this.order.order_detail[k].total=parseInt(this.order.order_detail[k].quantity) * parseInt(this.order.order_detail[k].price)
        }
      },
      onQualityChange(k,event){
        if(event==null) return
        debugger
        k=parseInt(k)
        if(this.order.order_detail[k].quantity>0){
          this.order.order_detail[k].total=parseInt(this.order.order_detail[k].quantity) * parseInt(this.order.order_detail[k].price)
        }else{
          this.order.order_detail[k].total=0
        }
      },
      addNewRow() {
        this.order.order_detail.push({
          id:null,
          product_id:null,
          price:0,
          status:0,
          total:0,
          ls_unit_ddl:[],
          unit_id:null,
          quantity:0,
          user_create_id:this.user_login.id,
          user_update_id:null,
          company_id:this.user_login.company_id,
          branch_id:this.user_login.branch_id,
          business:this.user_login.company.business
        })
      },
      removeRow(k){
        if(k==null || parseInt(k)<0) return
        let itm=this.order.order_detail[k]
        if(itm && itm.id){
          // confirm delete
          this.confirmRemoveDetail(k)
        }else{
          debugger
          this.order.order_detail.splice(k, 1)
        }
      },

      confirmRemoveDetail(k) {
        this.$bvModal.msgBoxConfirm(this.$t("common.delete_confirm"), {
        title: this.$t("common.confirm_delete"),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t("common.delete"),
        cancelTitle: this.$t("common.cancel"),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
        .then(value => {
          if(value!=true) return
          debugger
          this.order.order_detail[k].status=-1
          let temp=_.sumBy(this.order.order_detail,"total")
          this.order.total=parseInt(temp-((temp)*(this.order.discount/100)))
        })
        .catch(err => {
          this.$toastr.error(this.$t("common.error_message"),this.$t("common.message"))
        })
      },
      getRowCount (items) {
        return items.length
      },

      rowClicked (item) {
        if(item==null || item.status<0) return
        this.order=item
        if(!Array.isArray(this.order.client_list)){
          this.order.client_list=JSON.parse(this.order.client_list.replace(/'/g, '"'))
        }
        for (var i = 0; i < item.order_detail.length; i++) {
          let element = item.order_detail[i]
          let product=this.ls_product_ddl.find(x=>x.id==element.product_id)
          if(product){
            var filteredArray  = this.ls_unit_ddl.filter(function(array_el){
              return product.product_units.filter(function(anotherOne_el){
                if(anotherOne_el.unit_id == array_el.id){
                  array_el.buy_price= anotherOne_el.buy_price
                  array_el.sell_price= anotherOne_el.sell_price
                }
                return anotherOne_el.unit_id == array_el.id
              }).length > 0
            })

            if(filteredArray.length<=0){
              filteredArray=this.ls_product_ddl
            }

            element.ls_unit_ddl=filteredArray
          }
        }
      },

      clear() {
        this.order={id:null,branch_id:null,order_detail:[],
        des:null,in_time:moment().format("HH:mm"),out_time:moment().add(1,"hour").format("HH:mm"),
        company_id:null,object_id:null,user_create_id:this.user_login.id,
        discount:this.user_login.company.discount,client_list:[],
        old_water:0,new_water:0,old_electric:0,new_electric:0,
        user_update_id:null,business:null,code:moment().valueOf().toString(),total:0}
        this.addNewRow()
        this.$v.$reset()
      },
      _validations () {
        return new Promise(resolve => {
          if (this.$v.$error || !this.$v.$pending) {
              return resolve()
          }
          let poll = setInterval(() => {
          if (!this.$v.$pending) {
              clearInterval(poll)
              resolve()
          }
          }, 200)
        })
      },

      async isValidOrder () {
        this.$v.order.$reset()
        this.$v.order.$touch()
        await this._validations()
        return Promise.resolve(!this.$v.order.$error)
      },

      async save_order() {
        var app = this
        debugger
        const isValid = await this.isValidOrder()
        if (!isValid) return
        if (app.processing === true) return

        app.processing = true
        if(app.order.id){
          app.order.user_update_id=app.user_login.id
          app.order.total=app.total_recal
          debugger
          app.$store.dispatch('st_order/update',{order:app.order,client:null,business:app.business})
          .then(function (resp) {
            app.get_ls_order()
            app.get_ls_object()
            app.clear()
            app.$toastr.success(app.$t('common.created_success'),app.$t("common.message"))
            app.processing = false
          }).catch(function (resp) {
            app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
            app.processing = false
          })
        }else{
          app.order.user_create_id=app.user_login.id
          app.order.business=app.user_login.company.business
          app.order.company_id=app.user_login.company_id
          app.order.branch_id=app.user_login.branch_id
          debugger
          app.order.total=app.total_recal

          app.$store.dispatch('st_order/create',{order:app.order,client:null,business:app.business})
          .then(function (resp) {
            app.get_ls_order()
            app.get_ls_object()
            app.clear()
            app.$toastr.success(app.$t('common.created_success'),app.$t("common.message"))
            app.processing = false
          }).catch(function (resp) {
            app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
            app.processing = false
          })
        }
      },

      async recoverObj(obj){
        let app=this
        if(obj.status>=0) return
        let co=await app.$store.dispatch('st_order/recover_obj',obj.id)
        if(co && co.data.ok){
          app.get_ls_order()
          app.get_ls_object()
          app.$toastr.success(app.$t("common.recovered_success"),app.$t("common.message"))
        }else{
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        }
      },

      confirmDelete(obj) {
        let app=this
        if(obj.status<0) return
        this.$bvModal.msgBoxConfirm(this.$t("common.delete_confirm"), {
        title: this.$t("common.confirm_delete"),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t("common.delete"),
        cancelTitle: this.$t("common.cancel"),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
        .then(value => {
          if(value!=true) return
          this.deleteObj(obj)
        })
        .catch(err => {
          this.$toastr.error(this.$t("common.error_message"),this.$t("common.message"))
        })
      },
      async deleteObj(obj) {
        let app=this
        let co=await app.$store.dispatch('st_order/delete_obj',obj.id)
        if(co&&co.data.ok){
          app.get_ls_order()
          app.get_ls_object()
          app.$toastr.success(app.$t("common.deleted_success"),app.$t("common.message"))
        }else{
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        }
      },

      searchLike_object:function(item){
        debugger
        return item.created_at.includes(this.filter_object)
        || item.updated_at&&item.updated_at.includes(this.filter_object)
        || item.company&&item.company.name.includes(this.filter_object)
        || item.name.includes(this.filter_object)
      },

      searchLike:function(item){
        debugger
        return item.created_at.includes(this.filter)
        || item.updated_at&&item.updated_at.includes(this.filter)
        || item.company&&item.company.name.includes(this.filter)
        || item.des&&item.des.includes(this.filter)
      }
    },
    mounted () {
      let vm =this
      vm.bill_id=vm.user_login.company.default_bill
      vm.per_page_object=100
      vm.order.code=moment().valueOf().toString()
      vm.order.discount=vm.user_login.company.discount
      vm.ls_gender=vm.option_gender
      vm.addNewRow()
      vm.get_ls_order()
      vm.get_ls_object()
      vm.get_ls_client_ddl({company_id:vm.user_login.company_id,branch_id:vm.user_login.branch_id})
      vm.get_ls_object_ddl({company_id:vm.user_login.company_id,branch_id:vm.user_login.branch_id})
      vm.get_ls_product_ddl({company_id:vm.user_login.company_id,branch_id:vm.user_login.branch_id,business:vm.user_login.company.business,supply_id:null})
      vm.get_ls_unit_ddl({company_id:vm.user_login.company_id,business:vm.user_login.company.business,lang:vm.$i18n.locale})
    },
  }
</script>
